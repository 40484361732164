import { ClientPlatform } from '@frientrip/domain';

export interface CollectionCondition {
  id: string;
  maxImageSize: number;
  imageWidth: number;
  imageHeight: number;
  platform: ClientPlatform[];
  exposureType: CollectionConditionExposureType;
}

export enum CollectionConditionExposureType {
  // 기획전 메인 화면
  MAIN = 'MAIN',
  //   기획전 상세 화면
  DETAIL = 'DETAIL',
}
