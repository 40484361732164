


























































import Vue from 'vue';
import ImageUploadContainer from '@/components/ImageUpload/ImageUploadBox.vue';
import { CollectionConditionExposureType } from '../model/condition';
import FripBadge from '@/components/FripComponents/FripBadge.vue';

export default Vue.extend({
  components: {
    ImageUploadContainer,
    FripBadge,
  },
  props: {
    image: {
      type: Object,
    },
    condition: {
      type: Object,
    },
  },
  data() {
    return { contentId: this.image.contentId, url: this.image.url };
  },
  methods: {
    convertExposuerTypeToValue(exposureType: CollectionConditionExposureType): {
      type: string;
      exposureText: string;
      description: string;
    } {
      let exposureText = '';
      let description = '';
      switch (exposureType) {
        case CollectionConditionExposureType.MAIN:
          exposureText = '메인 화면';
          break;
        case CollectionConditionExposureType.DETAIL:
          exposureText = '상세 화면';
          description =
            '※ 상세화면 이미지를 넣지 않는경우 동일 플랫폼의 메인화면 이미지를 노출합니다. ';
          break;
      }
      return {
        type: 'warning',
        exposureText: exposureText,
        description: description,
      };
    },
    sendContentId(): void {
      console.log('aa', this.contentId);
      this.$emit('input', {
        contentId: this.contentId,
        url: this.url,
        condition: this.condition,
      });
    },
  },
});
