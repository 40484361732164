var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-table',{ref:"table",staticClass:"table-responsive table-flush align-items-center",attrs:{"header-row-class-name":"thead-light","border":"","data":_vm.options}},[_c('el-table-column',{attrs:{"label":"선택","prop":""}},[_c('b-form-checkbox',{attrs:{"id":"optionSelect","name":"optionSelect","value":"accepted","unchecked-value":"not_accepted"}})],1),_c('el-table-column',{attrs:{"label":"옵션 1","prop":"remove"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.option1))]}}])}),_c('el-table-column',{attrs:{"label":"옵션 2","prop":"remove"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.option2))]}}])}),_c('el-table-column',{attrs:{"label":"옵션 3","prop":"remove"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.option3))]}}])}),_c('el-table-column',{attrs:{"label":"가격","prop":"remove"}}),_c('el-table-column',{attrs:{"label":"사용 가능 기간","prop":"remove"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.usableTerm.day))]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }