



































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';

export default Vue.extend({
  name: 'OptionSelectList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    options: {
      type: Array,
    },
  },
});
